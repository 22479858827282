<template>
  <div class="container">
    <el-image
      class="banner"
      :src="require('@as/event/join/banner.png')"
    ></el-image>
    <div class="main">
      <div class="left">
        <div class="tips">
          汇律网尊重并保护所有使用汇律网电子日刊/周刊用户的个人隐私权。将对您填写的信息采取严密保护措施，不会主动泄漏给第三方。清科有可能使用这一信息与您进行联系以便您了解相关产品及服务，如果您不同意汇律网接收其他服务信息，请在此标注。
        </div>
        <div class="form">
          <el-form
            :model="ruleForm"
            ref="ruleForm"
            label-width="90px"
            class="demo-ruleForm"
          >
            <el-form-item label="姓名" prop="name">
              <div class="mark">*</div>
              <el-input v-model="ruleForm.name" placeholder="姓名"></el-input>
            </el-form-item>
            <el-form-item label="手机号" prop="mobile">
              <div class="mark">*</div>
              <el-input
                v-model="ruleForm.mobile"
                placeholder="13112341234"
              ></el-input>
            </el-form-item>

            <el-form-item label="活动规模">
              <div class="mark">*</div>
              <el-radio-group v-model="ruleForm.scale">
                <el-radio label="200人以下"></el-radio>
                <el-radio label="200-500人"></el-radio>
                <el-radio label="500-1000人"></el-radio>
                <el-radio label="1000人以上"></el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="活动需求">
              <div class="mark">*</div>
              <el-checkbox-group v-model="ruleForm.demand">
                <el-checkbox label="活动场地"></el-checkbox>
                <el-checkbox label="活动网站"></el-checkbox>
                <el-checkbox label="报名人数"></el-checkbox>
                <el-checkbox label="电子签到"></el-checkbox>
                <el-checkbox label="模机分离"></el-checkbox>
                <el-checkbox label="数据统计"></el-checkbox>
              </el-checkbox-group>
            </el-form-item>
            <el-form-item label="公司/单位" prop="company">
              <div class="mark">*</div>
              <el-input
                v-model="ruleForm.company"
                placeholder="公司/单位"
              ></el-input>
            </el-form-item>
            <el-form-item label="城市" prop="address">
              <div class="mark">*</div>
              <el-input
                v-model="ruleForm.address"
                placeholder="城市"
              ></el-input>
            </el-form-item>
            <el-form-item label="您的其他要素" prop="other">
              <el-input v-model="ruleForm.other"></el-input>
            </el-form-item>

            <div class="submit_btn" @click="toTake()">提交</div>
          </el-form>
        </div>
      </div>
      <div class="right">
        <div class="head">
          <div class="title">
            <el-image
              class="title_icon"
              :src="require('@as/event/join/icon_1.png')"
            ></el-image>
            <div>合作咨询</div>
          </div>
          <div class="title_more">MORE</div>
        </div>
        <div class="refer">
          <div class="row last" v-for="item in 3" v-bind:key="item">
            <el-image
              class="img"
              :src="require('@as/serve/knowledge/banner.png')"
            ></el-image>
            <div class="info">
              <div class="title">媒体合作咨询</div>
              <div class="subtitle">姓名：陈俊</div>
              <div class="subtitle">电话：2485</div>
              <div class="subtitle">邮箱：2485@163.com</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Activity } from '@api'
export default {
  data() {
    return {
      ruleForm: {
        name: '',
        mobile: '',
        scale: '',
        demand: [],
        company: '',
        address: '',
        other: '',
      },
    }
  },
  methods: {
    toTake() {
      const { ruleForm } = this
      for (let i in ruleForm) {
        if (!this.isValid(i, ruleForm[i])) return
      }
      Activity.custom(
        Object.assign(
          {
            userId: this.$store.state.uid,
          },
          ruleForm
        )
      ).then(() => {
        this.$router.go(-1)
      })
    },
    isValid(keys, val) {
      const rules = {
        name: '姓名',
        mobile: '手机号',
        scale: '活动规模',
        demand: '活动需求',
        company: '公司/单位',
        address: '城市',
      }
      if (rules[keys] && !val.length) {
        this.$message.error(`${rules[keys]}不能为空`)
        return false
      }
      return true
    },
  },
}
</script>

<style lang="less" scoped>
.container {
  margin: 0 auto 55px;
  width: 1200px;
  .banner {
    width: 1200px;
    height: 240px;
  }
  .main {
    margin-top: 20px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 1200px;
    .left {
      padding: 48px 87px;
      width: 850px;
      box-sizing: border-box;
      background: #fff;
      .tips {
        font-size: 13px;
        font-family: PingFangSC;
        font-weight: 400;
        color: #afafaf;
        line-height: 20px;
      }
      .form {
        margin-top: 50px;
        & /deep/ .el-form-item {
          margin-bottom: 30px;
        }
        & /deep/ .el-form-item__label {
          text-align: left;
          font-size: 13px;
          color: #595757;
        }
        & /deep/ .el-form-item__content {
          display: flex;
        }
        & /deep/ .el-checkbox {
          margin-right: 50px;
        }
        & /deep/ .el-radio-group {
          line-height: 40px;
        }
        .mark {
          margin-right: 26px;
          color: #c6091a;
        }
        .submit_btn {
          margin-top: 40px;
          padding: 10px 305px;
          color: #fff;
          background: #1c9ab1;
        }
      }
    }
    .right {
      padding: 20px 27px 0;
      flex-shrink: 0;
      width: 330px;
      background: #fff;
      box-sizing: border-box;
      .head {
        display: flex;
        justify-content: space-between;
        .title {
          padding-bottom: 6px;
          display: flex;
          align-items: center;
          font-size: 18px;
          font-family: PingFangSC;
          font-weight: 400;
          color: #595757;
          border-bottom: 2px solid #4a97ad;
          .title_icon {
            margin-right: 5px;
            width: 22px;
            height: 20px;
          }
        }
        .title_more {
          padding-bottom: 6px;
          flex: 1;
          font-size: 10px;
          font-family: PingFangSC;
          font-weight: 400;
          color: #a6a7a7;
          text-align: right;
          border-bottom: 1px solid #dedede;
        }
      }
      .refer {
        margin: 20px -27px 0;
        .row {
          padding: 17px 33px;
          display: flex;
          align-items: flex-start;
          cursor: pointer;
          &:hover {
            background: #f0fcff;
            box-shadow: 2px 2px 6px 0 rgba(000, 000, 000, 0.1);
          }
          &:not(:last-child) {
            border-bottom: 1px solid #dedede;
          }
          &:last-child {
            padding-bottom: 37px;
          }
          .img {
            margin-right: 15px;
            flex-shrink: 0;
            width: 42px;
            height: 42px;
            border-radius: 50%;
          }
          .info {
            .name {
              font-size: 14px;
              font-family: PingFangSC;
              font-weight: 400;
              color: #666666;
            }

            .title {
              margin: 10px 0 12px;
              font-size: 15px;
              font-family: PingFangSC;
              font-weight: 500;
              color: #595757;
            }
            .subtitle {
              margin-top: 10px;
              font-size: 13px;
              font-family: PingFangSC;
              font-weight: 400;
              color: #a7a8a8;
            }
          }
        }
      }
    }
  }
}
</style>